import { Maquinas, Apontamentos } from "./../app/common/interfaceApp";
import { defineStore } from "pinia";
import { useAppStore } from "./app";

declare let frappe: any;

export const useAppMaquinas = defineStore({
  id: "appMaquinas",
  state: (): {
    resourceMaquinas: Maquinas[],
    showModalMaquina: boolean,
  } => ({
    resourceMaquinas: [],
    showModalMaquina: false,
  }),

  getters: {},
  actions: {
    set_tp_parada(apontamento: Apontamentos) {
      if (!apontamento) return null
      if (apontamento.tipo == 'Parada') {
        if ((apontamento.manutencao == 1) && (apontamento.setup == 0)) { return 'Manutenção'; }
        else if ((apontamento.setup == 1) && (apontamento.manutencao == 0)) { return 'Setup'; }
        else { return 'Parada'; }
      } else { return null; }
    },

    async listar_maquinas() {
      try {
        this.resourceMaquinas = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          { limit: 15 }
        );

        if (res.message && Array.isArray(res.message)) {
          res.message.forEach((maquina: any) => {
            const apontamentos: Apontamentos[] = [];

            if (Array.isArray(maquina.apontamentos)) {
              maquina.apontamentos.forEach((apontamento: Apontamentos) => {
                apontamentos.push({
                  name: apontamento.name,
                  nome_operador: apontamento.nome_operador,
                  recurso: apontamento.recurso,
                  desc_recurso: apontamento.desc_recurso,
                  dt_inicio: apontamento.dt_inicio,
                  dt_fim: apontamento.dt_fim,
                  ordem_de_producao: apontamento.ordem_de_producao,
                  status: apontamento.status,
                  observacao: apontamento.observacao,
                  tipo: apontamento.tipo,
                  serial: apontamento.serial,
                  motivo_de_parada: apontamento.motivo_de_parada,
                  desc_motivo_parada: apontamento.desc_motivo_parada,
                  origem_apontamento: apontamento.origem_apontamento,
                  turno: apontamento.turno,
                  status_sensor: apontamento.status_sensor,
                  motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
                  setup: apontamento.setup,
                  manutencao: apontamento.manutencao
                });
              });
            }

            const existingMachine = this.resourceMaquinas.find(m => m.id === maquina.name);

            if (!existingMachine) {
              this.resourceMaquinas.push({
                id: maquina.name,
                imagem: maquina.imagem,
                leitor: maquina.leitor,
                centro_de_custo: maquina.centro_de_custo,
                nome: maquina.nome,
                operador_padrao: maquina.operador_padrao,
                tipo_apontamento: maquina.tipo_apontamento,
                apontamentos: apontamentos,
                state_sensor: maquina.state_sensor,
                dt_atualizacao_sensor: maquina.dt_atualizacao_sensor
              });
            }

            const apontamentoAberto = maquina.apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

            if (apontamentoAberto) {
              useAppStore().set_apontamento_realtime(apontamentoAberto);
            }

            useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto");
          });
        } else {
          console.error("Resposta inesperada do servidor:", res);
        }
      } catch (error) {
        console.error("Erro ao listar máquinas:", error);
        throw new Error(error);
      }
    },

    update_recurso_realtime(recurso_doc: any) {
      const apontamentos = [] as Apontamentos[];

      if (Array.isArray(recurso_doc.apontamentos)) {
        recurso_doc.apontamentos.forEach((apontamento: Apontamentos) => {
          apontamentos.push({
            name: apontamento.name,
            nome_operador: apontamento.nome_operador,
            recurso: apontamento.recurso,
            desc_recurso: apontamento.desc_recurso,
            dt_inicio: apontamento.dt_inicio,
            dt_fim: apontamento.dt_fim,
            ordem_de_producao: apontamento.ordem_de_producao,
            status: apontamento.status,
            observacao: apontamento.observacao,
            tipo: apontamento.tipo,
            serial: apontamento.serial,
            motivo_de_parada: apontamento.motivo_de_parada,
            desc_motivo_parada: apontamento.desc_motivo_parada,
            origem_apontamento: apontamento.origem_apontamento,
            turno: apontamento.turno,
            status_sensor: apontamento.status_sensor,
            motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
            setup: apontamento.setup,
            manutencao: apontamento.manutencao
          });
        });
      }

      const new_data = {
        id: recurso_doc.name,
        imagem: recurso_doc.imagem,
        leitor: recurso_doc.leitor,
        centro_de_custo: recurso_doc.centro_de_custo,
        nome: recurso_doc.nome,
        operador_padrao: recurso_doc.operador_padrao,
        tipo_apontamento: recurso_doc.tipo_apontamento,
        apontamentos: apontamentos,
        state_sensor: recurso_doc.state_sensor
      };

      const found = this.resourceMaquinas.find((data) => data.id == recurso_doc.name);


      if (found) {
        useAppStore().apontamentos_historico = [];

        const apontamentoAberto = apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

        if (apontamentoAberto) {
          useAppStore().set_apontamento_realtime(apontamentoAberto);
        }

        useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto");

        const new_resource = this.resourceMaquinas.map((data) =>
          data.id == recurso_doc.name ? new_data : data
        );

        this.resourceMaquinas = new_resource;

      } else { this.resourceMaquinas.push(new_data) }
    },

    update_apontamento_realtime(data) {
      if (data.recurso && useAppStore().maquina_sel.id === data.recurso.name) {

        console.log('update_apontamento_realtime', data);

        const { apontamento } = data;
  
        const filteredApontamento: Apontamentos = {
          name: apontamento.name,
          nome_operador: apontamento.nome_operador,
          recurso: apontamento.recurso,
          desc_recurso: apontamento.desc_recurso,
          dt_inicio: apontamento.dt_inicio,
          dt_fim: apontamento.dt_fim,
          ordem_de_producao: apontamento.ordem_de_producao,
          status: apontamento.status,
          observacao: apontamento.observacao,
          tipo: apontamento.tipo,
          serial: apontamento.serial,
          motivo_de_parada: apontamento.motivo_de_parada,
          desc_motivo_parada: apontamento.desc_motivo_parada,
          origem_apontamento: apontamento.origem_apontamento,
          turno: apontamento.turno,
          status_sensor: apontamento.status_sensor,
          motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
          setup: apontamento.setup,
          manutencao: apontamento.manutencao
        };

        if (apontamento.status === 'Fechado') {
          useAppStore().apontamentos_historico = [
            filteredApontamento,
            ...useAppStore().apontamentos_historico
          ];
        } else {
          useAppStore().set_apontamento_realtime(apontamento);
        }

      }
    },
  }, persist: { enabled: true }
});
